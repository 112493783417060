import { Container, AppBar, Toolbar, Typography, IconButton, Button, Card, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio, SwipeableDrawer, TextField, Skeleton } from '@mui/material';
import { ArrowBackOutlined, DeleteOutlineOutlined, SearchOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function SelecionarEndereco(props) {

  const { window } = props;
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [currentEndereco, setCurrentEndereco] = useState("");
  const [nomeEndereco, setNomeEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [localidade, setLocalidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [uf, setUf] = useState("");
  const [nome, setNome] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [enderecoEntrega, setEnderecoEntrega] = useState("");
  const [enderecos, setEnderecos] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const [semLogradouro, setSemLogradouro] = useState(false);
  const [semBairro, setSemBairro] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msgSnack, setMsgSnack] = React.useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [modalAlert, setModalAlert] = React.useState(false);

  const navigate = useNavigate();
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleBack = () => {
    navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handleCepChange = (event) => {
    setCep(event.target.value)
  };

  const handleNome = (event) => {
    setNome(event.target.value)
  };

  const handleLogradouro = (event) => {
    setLogradouro(event.target.value)
  };

  const handleBairro = (event) => {
    setBairro(event.target.value)
  };

  const handleNumero = (event) => {
    setNumero(event.target.value)
  };

  const handleComplemento = (event) => {
    setComplemento(event.target.value)
  };

  const handleCadastrar = () => {
    handleOpenLoading();
    const isLogged = Cookies.get('isLogged');

    if (isLogged) {
      if (!nome || !numero || numero.length === 0) {
        handleSnack("Por favor, preencha todos os campos corretamente para prosseguir.");
        return
      }

      const telefone = Cookies.get('tUsr').replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "");
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/cadastro-endereco.php`, {
        telefone: telefone.trim(),
        nome: nome.trim(),
        endereco: `${logradouro}, ${numero}, ${bairro}, ${localidade}, ${uf}, ${cep}, ${complemento}`.trim()
      })
        .then(response => {
          response = response.data
          getEnderecos();
          setOpen(false);
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
        );
    } else {
      if (!nome || !numero || numero.length === 0) {
        handleSnack("Por favor, preencha todos os campos corretamente para prosseguir.");
        return
      }
      Cookies.set('eEA', `${logradouro}, ${numero}, ${bairro}, ${localidade}, ${uf}, ${cep}, ${complemento}`, { expires: 7 });
      Cookies.set('nmEA', nome, { expires: 7 });
      Cookies.set('iEA', "Avs", { expires: 7 });
      navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }

  };

  const handleContinuarCep = () => {
    handleOpenLoading();
    axios.get('https://viacep.com.br/ws/' + cep + '/json')
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Por favor, preencha todos os campos corretamente para prosseguir.");
          setHasAddress(false)
          return
        }
        if (response.erro) {
          handleSnack("Não foi possivel encontrar o CEP, por favor verifique sua conexão com a internet.");
          setHasAddress(false)
          return
        }
        setHasAddress(true)
        setLogradouro(response.logradouro)
        setBairro(response.bairro)
        setLocalidade(response.localidade)
        setUf(response.uf)
        response.logradouro ? setSemLogradouro(false) : setSemLogradouro(true)
        response.bairro ? setSemBairro(false) : setSemBairro(true)
        handleCloseLoading();
      })
      .catch(error => handleErrorCep());
  };

  const handleErrorCep = () => {
    setHasAddress(false)
    handleSnack("Não foi possivel encontrar o cep, por favor verifique sua conexão com a internet.");
    handleCloseLoading();
  };

  const handleNew = () => {
    setHasAddress(false)
    setLogradouro("")
    setBairro("")
    setLocalidade("")
    setUf("")
    setOpen(true);
    handleCloseLoading();
  };

  const handleSelecionar = () => {
    if (enderecoEntrega) {
      Cookies.set('tE', 0, { expires: 7 });
    }
    Cookies.set('eEA', enderecoEntrega, { expires: 7 });
    Cookies.set('nmEA', nomeEndereco, { expires: 7 });
    Cookies.set('iEA', currentEndereco, { expires: 7 });
    navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handleEndereco = (endereco) => {
    setCurrentEndereco(endereco.IDEND_CL);
    setNomeEndereco(endereco.END_NM);
    setEnderecoEntrega(endereco.END_CL);
  };

  const handleRemove = (endereco) => {
    setCurrentEndereco(endereco.IDEND_CL);
    handleOpenModal("Atenção", `Essa é uma ação irreversível, você confirma a exclusão do endereço "${endereco.END_CL}" de sua conta?`)
  };

  const handleExcluirEndereco = (endereco) => {

    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/deletar-endereco.php`, {
      id: currentEndereco
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel deletar seu endereço, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "falha") {
          handleSnack("Não foi possivel deletar seu endereço, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "sucesso") {
          getEnderecos();
          handleSnack("Endereço deletado com sucesso.");
          setModalAlert(false);
          return;
        }
        handleSnack("Não foi possivel deletar seu endereço, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    console.log(currentEndereco)
  };

  const handleOpenModal = (titleModal, descriptionModal) => {
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const isLogged = Cookies.get('isLogged');

    if (isLogged) {
      getEnderecos()
      const idSelect = Cookies.get('iEA');
      if (idSelect) {
        const nomeSelect = Cookies.get('nmEA');
        const enderecoSelect = Cookies.get('eEA');
        setCurrentEndereco(idSelect)
        setNomeEndereco(nomeSelect);
        setEnderecoEntrega(enderecoSelect);
      }
    } else {
      handleNew();
    }
  }, []);

  const getEnderecos = () => {
    handleOpenLoading();
    const telefone = Cookies.get('tUsr').replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "");
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/enderecos.php`, {
      numero: telefone.trim()
    })
      .then(response => {
        response = response.data
        setEnderecos(response)
        handleCloseLoading();
        if (!response) {
          Cookies.remove('eEA');
          Cookies.remove('nmEA');
          Cookies.remove('iEA');
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Endereços
            {/* <IconButton size="large" color="inherit" aria-label="menu" sx={{ mr: 0 }} style={{ color: "#FFF", marginLeft: "auto" }} onClick={handleNew}>
              <SearchOutlined />
            </IconButton> */}
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        <Dialog
          open={modalAlert}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Fechar</Button>
            <Button onClick={handleExcluirEndereco}>Confirmar</Button>
          </DialogActions>
        </Dialog>
        {!enderecos && (
          <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
            <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

              <img src="https://dedicado.mely.online/imagens/no-order.png" />

              <div style={{ height: "10px" }}></div>

              Você não possui endereços cadastrados <br />Que tal cadastrar agora?

              <div style={{ height: "20px" }}></div>

              <Button variant="text" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleNew}>Cadastrar novo endereço</Button>
            </Typography>
          </Typography>
        )}

        {enderecos && (
          <>
            <Button variant="contained" fullWidth startIcon={<SearchOutlined />} style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleNew}>
              Buscar novo CEP
            </Button>
            {enderecos.length === 0 && (
              <>
                <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    action={
                      <IconButton size="large" color="inherit" aria-label="menu" sx={{ mr: 0 }} style={{ color: "#FF6347", marginLeft: "auto" }}>
                        <DeleteOutlineOutlined />
                      </IconButton>
                    }
                    title={<Skeleton animation="wave" height={30} width="100%" />}
                    subheader={<Skeleton animation="wave" height={30} width="100%" />}
                  />
                </Card>
                <div style={{ height: "10px" }}></div>
              </>
            )}
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
              <div style={{ height: "10px" }}></div>
              {enderecos.map((endereco) => (
                <>
                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      avatar={
                        <FormControl value={endereco.END_CL}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={() => handleEndereco(endereco)}
                          >
                            <FormControlLabel checked={currentEndereco === endereco.IDEND_CL} value={currentEndereco} control={<Radio />} />
                          </RadioGroup>
                        </FormControl>
                      }
                      action={
                        <IconButton size="large" color="inherit" aria-label="menu" sx={{ mr: 0 }} style={{ color: "#FF6347", marginLeft: "auto" }} onClick={() => handleRemove(endereco)}>
                          <DeleteOutlineOutlined />
                        </IconButton>
                      }
                      title={endereco.END_NM}
                      titleTypographyProps={{ fontSize: "12px", fontWeight: "500", marginLeft: "-20px", color: "#999" }}
                      subheader={endereco.END_CL}
                      subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", marginLeft: "-20px", color: "#1B1B1B" }}
                    />
                  </Card>
                  <div style={{ height: "10px" }}></div>
                </>
              ))}
            </Typography>
          </>
        )}

        <SwipeableDrawer
          container={container}

          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

          {!hasAddress ? (
            <>
              <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#464444", fontWeight: "bold" }}>
                Preencha o CEP (apenas números)
              </Typography>

              <div style={{ height: "6px" }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={cep}
                  onChange={handleCepChange}
                  variant="outlined"
                  placeholder="CEP"
                  type="text"
                />
              </Typography>
              <Button variant="contained" size="large" style={{ textTransform: "none", margin: "20px" }} onClick={handleContinuarCep}>Continuar</Button>
            </>
          ) : (
            <>
              <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#999", fontWeight: "bold" }}>
                Preencha número e dê um nome ao endereço
              </Typography>
              <div style={{ height: "10px" }}></div>
              {logradouro && (
                <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#464444", fontWeight: "bold" }}>
                  {logradouro},
                </Typography>
              )}
              <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#999", fontWeight: "bold" }}>
                {bairro ? `${bairro}, ` : ""} {localidade}, {uf} - {cep}
              </Typography>
              <div style={{ height: "6px" }}></div>
              {semLogradouro && (
                <>
                  <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                    <TextField
                      fullWidth
                      hiddenLabel
                      value={logradouro}
                      onChange={handleLogradouro}
                      variant="outlined"
                      placeholder="Logradouro (Nome da rua)"
                      type="text"
                    />
                  </Typography>
                  <div style={{ height: "12px" }}></div>
                </>
              )}
              {semBairro && (
                <>
                  <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                    <TextField
                      fullWidth
                      hiddenLabel
                      value={bairro}
                      onChange={handleBairro}
                      variant="outlined"
                      placeholder="Bairro"
                      type="text"
                    />
                  </Typography>
                  <div style={{ height: "12px" }}></div>
                </>
              )}
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={nome}
                  onChange={handleNome}
                  variant="outlined"
                  placeholder="Nome (casa)"
                  type="text"
                />
              </Typography>
              <div style={{ height: "12px" }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={numero}
                  onChange={handleNumero}
                  variant="outlined"
                  placeholder="Número"
                  type="text"
                />
              </Typography>
              <div style={{ height: "12px" }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={complemento}
                  onChange={handleComplemento}
                  variant="outlined"
                  placeholder="Complemento (opcional)"
                  type="text"
                />
              </Typography>
              <Button variant="contained" size="large" style={{ textTransform: "none", margin: "20px" }} onClick={handleCadastrar}>Continuar</Button>
            </>
          )}


        </SwipeableDrawer>

        {enderecos && Cookies.get('isLogged') && (
          <div style={{ position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
            <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "none", fontWeight: "700" }} onClick={handleSelecionar}>
              Selecionar endereço
            </Button>
          </div>
        )}

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default SelecionarEndereco;