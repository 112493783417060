import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

import Cookies from 'js-cookie';
import SideMenu from '../../components/SideMenu';
import Home from './Home';
import Pedidos from './Pedidos';
import Cardapio from './Cardapio';
import Inventario from './Inventario';
import QRCodeScreen from './QRCode';
import Personalizar from './Personalizar';
import PDV from './PDV';
import Configuracoes from './Configuracoes';
import Suporte from './Suporte';
import '../../components/Scrollbar.css';
import Clientes from './Clientes';
import Despesas from './Despesas';

function HomeRouter() {

  const state = useLocation().state;
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [value, setValue] = useState('');
  const [updateP, setUpdate] = useState('');

  useEffect(() => {

    setColorPrimary(Cookies.get("clPmr"))
    setColorSecondary(Cookies.get("clScr"))
    setColorTertiary(Cookies.get("clTcr"))

  }, []);

  const update = (event, newValue) => {
    setUpdate(newValue);
  };

  const handleChange = (event, newValue) => {
    console.log(event)
    if (event == "pg") {
      setUpdate(`STPG___${newValue}`);
    } else {
      setUpdate(`UPDTCLP___${Cookies.get("clPmr").split(", ")}`);
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }
    setValue(newValue);
  };

  const renderContent = () => {
    switch (value) {
      case 'home':
        return <Home onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'cardapio':
        return <Cardapio onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'pedidos':
        return <Pedidos onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'inventario':
        return <Inventario onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'qrcode':
        return <QRCodeScreen onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'personalizar':
        return <Personalizar onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'pdv':
        return <PDV onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'configuracoes':
        return <Configuracoes onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'suporte':
        return <Suporte onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'clientes':
        return <Clientes onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'despesas':
        return <Despesas onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        setValue("home")
        return <Home />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <SideMenu onChange={handleChange} update={updateP} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />

      {renderContent()}

    </ThemeProvider>
  );
}

export default HomeRouter;