import { Box, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';

// Cookies.set('isLogged', true, { expires: 7 });  
// const valorCookie = Cookies.get('nomeDoCookie');
// Cookies.remove('cookieExpiraEm');

export const formatarData = (data) => {
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses são indexados de 0 a 11
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
}

export const returnDia = (dia) => {
  switch (dia) {
    case "1":
      return "Seg";
    case "2":
      return "Ter";
    case "3":
      return "Qua";
    case "4":
      return "Qui";
    case "5":
      return "Sex";
    case "6":
      return "Sab";
    case "7":
      return "Dom";
  }
}

export const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name) => {
  if (name.includes(" ")) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0].toUpperCase()}${name[1][0].toUpperCase()}`,
    };
  }
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const toReal = (valor) => {
  if (valor) {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    return "0,00"
  }
}

export const toHour = (timestamp) => {
  let unixTimestamp = timestamp
  let date = new Date(unixTimestamp * 1000);
  let hours = "0" + date.getHours();
  let minutes = "0" + date.getMinutes();
  let formatTime = hours.substr(-2) + ':' + minutes.substr(-2);
  return formatTime
}

export const createNewTheme = (colorPrimary, colorSecondary, colorTertiary) => {
  return createTheme({
    palette: {
      primary: {
        main: `rgb(${colorPrimary})`,
      },
      secondary: {
        main: `rgb(${colorSecondary})`,
      },
      tertiary: {
        main: `rgb(${colorTertiary})`,
      }
    },
  });
};

export const traduzirMes = (mes) => {
  const meses = {
    'Jan': 'Jan',
    'Feb': 'Fev',
    'Mar': 'Mar',
    'Apr': 'Abr',
    'May': 'Mai',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Ago',
    'Sep': 'Set',
    'Oct': 'Out',
    'Nov': 'Nov',
    'Dec': 'Dez'
  };
  return meses[mes];
}

export const rgbaToHex = (r, g, b, a) => {
  r = Math.round(r).toString(16).padStart(2, '0');
  g = Math.round(g).toString(16).padStart(2, '0');
  b = Math.round(b).toString(16).padStart(2, '0');
  a = Math.round(a * 255).toString(16).padStart(2, '0');

  return `#${r}${g}${b}${a}`;
}

export const hexToRgba = (hex) => {
  let r = 0, g = 0, b = 0, a = 1;

  if (hex.charAt(0) === '#') {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  }

  if (hex.length === 8) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
    a = parseInt(hex.slice(6, 8), 16) / 255;
  }

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export const getDatahora = (data) => {
  if (data) {
    let [dia, mes, a, restante] = data.split(" ");
    mes = traduzirMes(mes);
    return `${dia} ${mes} - ${restante}`;
  }
  return ""
};

export const getDataMes = (data) => {
  if (data) {
    let [dia, mes, a, restante] = data.split(" ");
    mes = traduzirMes(mes);
    return `${dia} ${mes}`;
  }
  return ""
};

export const maskCpfCnpj = (text) => {
  var string = text
  if (string.length > 14) {
    string = string.replace(/\D/g, "")
    string = string.replace(/(\d{2})(\d)/, "$1.$2")
    string = string.replace(/(\d{3})(\d)/, "$1.$2")
    string = string.replace(/(\d{3})(\d)/, "$1/$2")
    string = string.replace(/(\d{4})(\d{1,2})$/, "$1-$2")
  } else {
    string = string.replace(/\D/g, "")
    string = string.replace(/(\d{3})(\d)/, "$1.$2")
    string = string.replace(/(\d{3})(\d)/, "$1.$2")
    string = string.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  }
  return string
}

export const maskPhone = (text) => {
  var string = text

  if (string.length > 14) {
    string = string.replace(/\D/g, "")
    string = string.replace(/^(\d\d)(\d)/g, "($1) $2")
    string = string.replace(/(\d{5})(\d)/, "$1-$2")
  } else {
    string = string.replace(/\D/g, "")
    string = string.replace(/^(\d\d)(\d)/g, "($1) $2")
    string = string.replace(/(\d{4})(\d)/, "$1-$2")
  }
  return string
}

export const parsePhones = (phoneString) => {
  const phoneRegex = /\(\d{2}\) \d{5}-\d{4}/g;
  const phones = phoneString.match(phoneRegex);
  return phones || [];
}

export const maskHoras = (text) => {
  let string = text.replace(/\D/g, "");
  if (string.length > 4) {
    string = string.substring(0, 4);
  }
  string = string.replace(/(\d{2})(\d{1,2})/, "$1:$2");
  return string
}

export const maskReais = (text) => {
  let string = text.replaceAll(",", "").replaceAll(".", "")

  string = string.replace(/\D/g, "");

  if (string.length > 3) {
    string = string.replace(/^0+/, "");
  }

  while (string.length < 3) {
    string = "0" + string;
  }

  const centavos = string.slice(-2);
  const inteiros = string.slice(0, -2);
  console.log(string)
  return `${inteiros},${centavos}`;
};