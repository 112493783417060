import { Container, AppBar, Toolbar, IconButton, CircularProgress, Box, Typography, Card, CardHeader, CardActionArea, CardMedia, CardContent, Avatar, Skeleton, Button } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';

import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Fidelidade() {

  const state = useLocation().state;

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [qtdPontos, setQtdPontos] = useState(0);
  const [produtoResgate, setProdutoResgate] = useState(null);
  const [urlBase, setUrlBase] = useState("");
  const [maxPontos, setMaxPontos] = useState(0);
  const [percentCircle, setPercentCircle] = useState(0);
  const [brindes, setBrindes] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleOpenModal = (titleModal, descriptionModal) => {
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
    handleCloseLoading();
  };

  const handleCloseModal = () => {
    setProdutoResgate(null);
    setModalAlert(false);
  };

  useEffect(() => {
    handleOpenLoading();
    
    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;
    
    if(colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const userIsLogged = Cookies.get('isLogged');
    if (userIsLogged) {
      let pontos = parseInt(Cookies.get('pUsr'));
      setQtdPontos(pontos)
      let newMax = 0
      while (newMax < pontos) {
        newMax += 500
        setMaxPontos(newMax)
      }
      if(isNaN(pontos)) {
        pontos = 0
        setQtdPontos(0)
      }
      if (pontos === 0 && newMax === 0) {
        setPercentCircle(100)
      } else {
        setPercentCircle(parseInt((pontos / newMax) * 100))
      }
    } else {
      setPercentCircle(100)
      alert("Realize o login ou cadastre-se para participar")
    }

    const url = Cookies.get('UBs');
    setUrlBase(url)
    getProdutos(url)

  }, []);

  const getProdutos = (url) => {

    axios.get(`${url}/produtos-menu.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        setBrindes(response.filter(item => item.valor_pontos > 0));
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleResgatar = (produto) => {
    const userIsLogged = Cookies.get('isLogged');
    if (userIsLogged) {
      const pontosUsuario = parseInt(Cookies.get('pUsr'));
      if(pontosUsuario < parseInt(produto.valor_pontos)) {
        handleSnack("Você não possui pontos suficientes para realizar o resgate!")
        return;
      }
      
      setProdutoResgate(produto);
      handleOpenModal("Atenção", `Você confirma o resgate do item ${produto.nome} por ${produto.valor_pontos} pontos?`);
    } else {
      handleSnack("Você precisa estar logado para efetuar um resgate!")
    }
  }

  const handleConfirmResgatar = () => {
    
    const detalhes = btoa(JSON.stringify([{
        id: produtoResgate.idprodutos,
        nome: produtoResgate.nome,
        descricao: produtoResgate.descricao,
        categoria: produtoResgate.categoria,
        valorOriginal: "0,00",
        valorComAdicionais: "0,00",
        valorTotal: "0,00",
        quantidade: 1,
        tipo: "",
        observacoes: "",
        adicionais: [],
        limiteestoque: produtoResgate.estoque,
      }]));
    Cookies.set('crU', detalhes, { expires: 7 });
    Cookies.set('bCrt', true, { expires: 7 });
    Cookies.set('vPt', produtoResgate.valor_pontos, { expires: 7 });
    navigate('/carrinho', { replace: true, state: { page: "cardapio", colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });

  }

  const handleBack = () => {
    if (page !== "perfil") {
      navigate("/perfil-loja", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
      return
    } else {
      navigate("/", { replace: true, state: { page: "perfil" } })
      return
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Fidelidade
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <Dialog
          open={modalAlert}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button onClick={handleConfirmResgatar}>Confirmar</Button>
          </DialogActions>
        </Dialog>

        <div style={{ height: "240px" }}></div>

        <Box sx={{ position: "absolute", top: "150px", left: "50%", transform: "translate(-50%, -50%)", margin: "0", display: 'inline-flex', alignItems: "center" }}>
          <CircularProgress variant="determinate" value={percentCircle} size={135} />
          <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="div" style={{ fontSize: "14", fontWeight: "bold", color: "#1B1B1B" }}>
              {qtdPontos}/{maxPontos}
            </Typography>
          </Box>
        </Box>

        <Typography component="div" style={{ fontSize: "14px", fontWeight: "bold", color: "#464444" }}>
          Para participar
        </Typography>

        <div style={{ height: "10px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#1B1B1B", width: "23px", height: "23px", fontSize: "12px" }} aria-label="recipe">
                1
              </Avatar>
            }
            title="Faça um compra de um de nossos produtos."
            titleTypographyProps={{ fontSize: "12px", fontWeight: "bold" }}
          />
        </Card>

        <div style={{ height: "16px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#1B1B1B", width: "23px", height: "23px", fontSize: "12px" }} aria-label="recipe">
                2
              </Avatar>
            }
            title="Após a compra você ganha pontos."
            titleTypographyProps={{ fontSize: "12px", fontWeight: "bold" }}
          />
        </Card>

        <div style={{ height: "16px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#1B1B1B", width: "23px", height: "23px", fontSize: "12px" }} aria-label="recipe">
                3
              </Avatar>
            }
            title="Acumule pontos e troque por brindes."
            titleTypographyProps={{ fontSize: "12px", fontWeight: "bold" }}
          />
        </Card>

        <div style={{ height: "28px" }}></div>

        <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
          Alguns brindes
        </Typography>

        <div style={{ height: "12px" }}></div>




        {brindes ? (

          <>
            {brindes.length === 0 && (
              <>
                <Typography style={{ fontSize: "13px", color: "#9C9C9C", textAlign: "left" }}>
                  Essa loja ainda não disponibilizou brindes, mas fique ligado que em breve teremos novidades!
                </Typography>
              </>
            )}

            <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>

              {brindes.map((produto) => (
                produto.estoque !== 0 && produto.estoque !== undefined && (
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }} onClick={() => handleResgatar(produto)}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="170"
                        image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.idprodutos}_0.png?${new Date().getTime()}`} onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                        }}
                      />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography gutterBottom style={{ fontSize: "14px", color: "#1B1B1B", marginBottom: "0px", fontWeight: "bold" }} component="div">
                          {produto.nome}
                        </Typography>
                        <Typography style={{ fontSize: "12px", color: "#999" }}>
                          {produto.valor_pontos} Pontos
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )
              ))}
            </Carousel>
          </>
        ) : (
          <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>
            <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
              <CardActionArea>
                <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                <CardContent style={{ textAlign: "left" }}>
                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>

                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
              <CardActionArea>
                <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                <CardContent style={{ textAlign: "left" }}>
                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>

                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
              <CardActionArea>
                <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                <CardContent style={{ textAlign: "left" }}>
                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>

                  <Typography component="div">
                    <Skeleton animation="wave" height={21} width="100%" />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Carousel>
        )}

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Fidelidade;