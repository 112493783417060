import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, toReal, createNewTheme, traduzirMes, toHour, getDataMes, getDatahora, stringAvatar } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, OutlinedInput, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { AttachFileOutlined, Cancel, CheckCircle, ChevronRight, Circle, Close, CloseOutlined, EditCalendarOutlined, FilterList, GridOnOutlined, KeyboardArrowRightOutlined, MoreVertOutlined, SearchOffOutlined, SearchOutlined, SettingsBackupRestoreOutlined, Stars, StarsOutlined, UndoOutlined, ViewStreamOutlined } from '@mui/icons-material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import axios from 'axios';
import Cookies from 'js-cookie';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingComponent from '../../components/LoadingComponent';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #dcdbdb`,
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#f2f2f2",
  padding: theme.spacing(2),
}));

const status = [
  'Todos os status',
  'Novo',
  'Preparando',
  'Entrega',
  'Finalizados',
  'Cancelados',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Clientes({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {
  const { windows } = props;

  const [tipoPedido, setTipoPedido] = useState(0);
  const [tipoTela, setTipoTela] = useState("lista");
  const [clientesOriginal, setClientesOriginal] = useState();
  const [clientes, setClientes] = useState();
  const [clienteSelecionado, setClienteSelecionado] = useState(0);
  const [expanded, setExpanded] = useState('panel1');
  const [valorDescontos, setValorDescontos] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [timestampInicio, setTimestampInicio] = useState("");
  const [timestampFim, setTimestampFim] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [isFiltrando, setIsFiltrando] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPeriodo, setAnchorElPeriodo] = useState(null);
  const [anchorElOpcoes, setAnchorElOpcoes] = useState(null);
  const [openModalRelatorio, setOpenModalRelatorio] = useState(false);
  const [periodoDe, setPeriodoDe] = useState(null);
  const [periodoAte, setPeriodoAte] = useState(null);
  const [relatorioPeriodoDe, setRelatorioPeriodoDe] = useState(null);
  const [relatorioPeriodoAte, setRelatorioPeriodoAte] = useState(null);

  const openOpcoes = Boolean(anchorElOpcoes);
  const openFiltros = Boolean(anchorEl);
  const openPeriodo = Boolean(anchorElPeriodo);
  const [statusRelatorio, setStatusRelatorio] = useState([]);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const selectFiltrosRelatorio = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('Todos os status')) {
      setStatusRelatorio(statusRelatorio.length === status.length ? [] : status);
    } else {
      setStatusRelatorio(typeof value === 'string' ? value.split(',') : value);
    }
  };
  const handleOpenOpcoes = (event) => {
    setAnchorElOpcoes(event.currentTarget);
  };

  const handleCloseOpcoes = () => {
    setAnchorElOpcoes(null);
  };

  const handleOpenFiltros = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFiltros = () => {
    setAnchorEl(null);
  };

  const handleOpenPeriodos = (event) => {
    setAnchorElPeriodo(event.currentTarget);
  };

  const handleClosePeriodos = () => {
    setAnchorElPeriodo(null);
  };

  const handleOpenRelatorios = () => {
    handleCloseOpcoes()
    setOpenModalRelatorio(true)
  };

  const handleCloseModalRelatorio = () => {
    setOpenModalRelatorio(false)
  };

  const handlePesquisar = (event) => {
    setClientes(clientesOriginal.filter(item => item.nome.toLowerCase().includes(event.target.value.toLowerCase()) || item.telefone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").includes(event.target.value)))
    setPesquisar(event.target.value);
  };

  const handleTipoTela = (newValue) => {
    setTipoTela(newValue);
  };

  const handleBaixarRelatorio = () => {
    handleOpenLoading();
    if (!statusRelatorio) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar pelo menos um status para baixar o relatório.`)
      return
    }
    if (!relatorioPeriodoDe || !relatorioPeriodoAte) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar as datas de inicio e fim para baixar o relatório.`)
      return
    }
    let statusEnviar = statusRelatorio.join(",")
    statusEnviar = statusEnviar.replace("Todos os status,", "").replace("Novo", "'R'").replace("Preparando", "'P'").replace("Entrega", "'E'").replace("Finalizados", "'F'").replace("Cancelados", "'C'")
    axios.post(`${urlBase}/baixar-pedidos.php?idestabelecimentos=1&status=${statusEnviar}&de=${relatorioPeriodoDe.unix()}&ate=${relatorioPeriodoAte.unix()}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `relatorio-${new Date().toLocaleDateString('pt-BR')}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        handleCloseLoading()
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  const getUsuarios = (url, inicio, fim) => {
    handleOpenLoading()
    axios.get(`${url}/clientes.php?idestabelecimentos=1&inicio=${inicio}&fim=${fim}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data
        if (response) {
          if (!clientesOriginal) {
            setClientesOriginal(response)
          }
          setClientes(response)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  useEffect(() => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');
    setUrlBase(urlBase)

    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999);
    const timestampFim = Math.floor(hoje.getTime() / 1000);
    const timestampInicio = timestampFim - 172800;
    setTimestampInicio(timestampInicio)
    setTimestampFim(timestampFim)
    getUsuarios(urlBase, timestampInicio, timestampFim)
  }, []);

  const validaZeroClientes = (clientes) => {
    if (clientes && clientes.length === 0) {
      return true
    }
    return false
  }

  const handleCliente = (cliente) => {
    setClienteSelecionado(cliente)
  }

  const handleAtualizarPedido = (event, produto, status) => {
    event.stopPropagation();
    let arrHora
    const timestampAtual = Math.floor(new Date().getTime() / 1000);
    let demaishoras = produto.demaishoras
    demaishoras ? arrHora = demaishoras.split(",") : arrHora = (",,").split(",");
    switch (status) {
      case "P":
        arrHora[0] = timestampAtual;
        break;
      case "E":
        arrHora[1] = timestampAtual;
        break;
      case "F":
        arrHora[2] = timestampAtual;
        break;
    }
    demaishoras = (`${arrHora[0]},${arrHora[1]},${arrHora[2]}`).replaceAll("undefined", "")
    const post_url = `${urlBase}/editar-pedido.php`;
    const json = JSON.stringify({
      idclientes: produto.idclientes,
      status: status,
      demaishoras: demaishoras
    });

    axios.post(post_url, json)
      .then(resp => {
        console.log(resp.data)
        getUsuarios(urlBase, timestampInicio, timestampFim);
      })
  }

  const getNomeCliente = (pedido) => {
    if (pedido.nomecliente && pedido.nomecliente.includes("--")) {
      const arr = pedido.nomecliente.split("--");
      return <>{arr[2].toUpperCase()}</>
    }
    return pedido.nomecliente
  }

  const getTipoPedido = (pedido) => {
    if (pedido.nomecliente && pedido.nomecliente.includes("--")) {
      const arr = pedido.nomecliente.split("--");
      return <>Mesa {arr[0]}</>
    }
    if (pedido.endereco == "Retirada") {
      return <>Retirada</>
    }
    return <>Entrega</>
  }

  const getButtons = (pedido, status) => {
    switch (status) {
      case "R":
        return <>
          <Button variant="text" color="error" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "C")}>Cancelar</Button>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "P")}>Confirmar</Button>
        </>
      case "P":
        return <>
          <IconButton color="error" style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}>
            <UndoOutlined />
          </IconButton>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "E")}>Avançar</Button>
        </>
      case "E":
        return <>
          <IconButton color="error" style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "P")}>
            <UndoOutlined />
          </IconButton>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "F")}>Confirmar</Button>
        </>
      case "F":
        return <>
          <Button variant="text" color="success" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}> <SettingsBackupRestoreOutlined />&nbsp;&nbsp;Reativar</Button>
        </>
      case "C":
        return <>
          <Button variant="text" color="success" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}> <SettingsBackupRestoreOutlined />&nbsp;&nbsp;Restaurar</Button>
        </>
    }

  }

  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <Dialog
        open={openModalRelatorio}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 640 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <AttachFileOutlined />&nbsp;&nbsp;Relatório
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalRelatorio}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>

          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            Status
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={statusRelatorio}
              onChange={selectFiltrosRelatorio}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {status.map((stats) => (
                <MenuItem key={stats} value={stats}>
                  <Checkbox checked={statusRelatorio.indexOf(stats) > -1} />
                  <ListItemText primary={stats} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>De</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={relatorioPeriodoDe}
                  onChange={(newValue) => setRelatorioPeriodoDe(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Até</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={relatorioPeriodoAte}
                  onChange={(newValue) => setRelatorioPeriodoAte(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
          </div>



        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseModalRelatorio} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          <Button variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={handleBaixarRelatorio}>Baixar relatório</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute", overflow: "hidden" }}>
        <Grid container spacing={2} style={{ padding: "16px 0px 16px 16px" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={7} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", height: "100vh" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Clientes</Typography>

                </div>

                <div>
                  <div style={{ height: 40 }} />
                  <Grid container spacing={2} style={{ maxHeight: "calc(100vh - 192px)", overflow: "auto", paddingBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
                    {clientes &&
                      <>
                        {validaZeroClientes(clientesOriginal) ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 328px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                                <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                                <div style={{ height: "10px" }}></div>

                                Não há clientes para listar

                                <div style={{ height: "20px" }}></div>
                              </div>
                            </Grid>
                          </>
                          :
                          <>
                            <TextField
                              fullWidth
                              hiddenLabel
                              value={pesquisar}
                              onChange={handlePesquisar}
                              variant="outlined"
                              size="large"
                              placeholder="Pesquisar por (Nome ou Telefone)"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <InputAdornment style={{ color: "#444" }}>
                                      <SearchOutlined />
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                            />
                            <Divider style={{ width: "100%" }} />
                            <div style={{ height: 20 }} />
                          </>
                        }

                        <table style={{ width: "100%", marginTop: 16 }}>
                          <tr>
                            <th style={{ width: 60 }}></th>
                            <th style={{ textAlign: "left" }}>Nome</th>
                            <th style={{ textAlign: "center", width: 120 }}>Gastos</th>
                          </tr>
                        </table>
                        {clientes.map((cliente) => (
                          <Tooltip title={"Clique para ver detalhes do cliente"}>
                            <table style={{ width: "100%", textAlign: "left", background: "#F2F2F2", borderRadius: 8, marginBottom: 6, cursor: "pointer" }} onClick={() => handleCliente(cliente)}>
                              <tr>
                                <td style={{ padding: 6, width: 60, textAlign: "center" }}><Avatar {...stringAvatar(cliente.nome)} style={{ width: "38px", height: "38px", fontSize: 14 }} /></td>
                                <td style={{ textAlign: "left" }}>{cliente.nome}</td>
                                <td style={{ textAlign: "center", width: 120 }}>
                                  <Typography style={{ background: "#6B9F29", fontWeight: "bold", paddingTop: 2, paddingBottom: 2, borderRadius: 4, margin: 10, color: "white" }}>
                                    R$ {toReal(parseFloat(cliente.total_valor_pedidos))}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </Tooltip>
                        ))}
                      </>
                    }

                  </Grid>
                </div>
              </Grid>

              {clienteSelecionado === 0 ?
                <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                    <img src="https://dedicado.mely.online/imagens/no-order-est.png" style={{ width: 155 }} />

                    <div style={{ height: "10px" }}></div>

                    Selecione um cliente ao lado para ver mais detalhes

                    <div style={{ height: "20px" }}></div>
                  </div>
                </Grid>
                :
                <>
                  <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                    <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{clienteSelecionado.nome}
                      <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setClienteSelecionado(0)}>
                        <Close />
                      </IconButton>
                    </Typography>
                    <div style={{ height: 10 }} />
                    <Divider style={{ width: "120%", marginLeft: -16 }} />
                    <div style={{ height: 24 }} />

                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados do cliente</Typography>
                    <div style={{ height: 10 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      E-mail
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.email}</label>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Telefone
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.telefone}</label>
                    </Typography>
                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />
                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados valores</Typography>
                    <div style={{ height: 10 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Pedidos
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.total_pedidos}</label>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Valor total
                      <b style={{ marginLeft: "auto" }}>R$ {toReal(parseFloat(clienteSelecionado.total_valor_pedidos))}</b>
                    </Typography>

                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "600", fontSize: 16, textAlign: "center" }}><Stars style={{ color: "#1976d2", marginBottom: -6 }} /> &nbsp;&nbsp; Pontos acumulados &nbsp;&nbsp; <Stars style={{ color: "#1976d2", marginBottom: -6 }} /></Typography>
                    <div style={{ height: 20 }} />
                    <Typography style={{ background: "#1976d2", color: "#fff", textAlign: "center", padding: 10, borderRadius: 8, fontWeight: "bold" }}>{clienteSelecionado.pontos ? clienteSelecionado.pontos : 0}</Typography>
                    <div style={{ height: 24 }} />

                  </Grid>

                  {isMobile &&
                    <SwipeableDrawer
                      container={container}
                      anchor="bottom"
                      open={clienteSelecionado !== 0}
                      onClose={() => setClienteSelecionado(0)}
                      disableSwipeToOpen={true}
                      disableDiscovery={true}
                      ModalProps={{
                        keepMounted: true,
                        style: { zIndex: "10000" }
                      }}
                    >
                      <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} style={{ padding: 16, overflowX: "hidden", overflowY: "auto" }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{clienteSelecionado.nome}
                          <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setClienteSelecionado(0)}>
                            <Close />
                          </IconButton>
                        </Typography>
                        <div style={{ height: 10 }} />
                        <Divider style={{ width: "120%", marginLeft: -16 }} />
                        <div style={{ height: 24 }} />

                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados do cliente</Typography>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          E-mail
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.email}</label>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Telefone
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.telefone}</label>
                        </Typography>
                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados valores</Typography>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Pedidos
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.total_pedidos}</label>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Valor total
                          <b style={{ marginLeft: "auto" }}>R$ {toReal(parseFloat(clienteSelecionado.total_valor_pedidos))}</b>
                        </Typography>

                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16, textAlign: "center" }}><Stars style={{ color: "#1976d2", marginBottom: -6 }} /> &nbsp;&nbsp; Pontos acumulados &nbsp;&nbsp; <Stars style={{ color: "#1976d2", marginBottom: -6 }} /></Typography>
                        <div style={{ height: 20 }} />
                        <Typography style={{ background: "#1976d2", color: "#fff", textAlign: "center", padding: 10, borderRadius: 8, fontWeight: "bold" }}>{clienteSelecionado.pontos ? clienteSelecionado.pontos : 0}</Typography>
                        <div style={{ height: 24 }} />
                      </div>
                    </SwipeableDrawer>
                  }
                </>
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Clientes;