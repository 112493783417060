import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, toReal, createNewTheme, traduzirMes, toHour, getDataMes, getDatahora } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, ListItemText, OutlinedInput, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { AttachFileOutlined, Cancel, CheckCircle, ChevronRight, Circle, Close, CloseOutlined, EditCalendarOutlined, FilterList, GridOnOutlined, KeyboardArrowRightOutlined, MoreVertOutlined, SettingsBackupRestoreOutlined, UndoOutlined, ViewStreamOutlined } from '@mui/icons-material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import axios from 'axios';
import Cookies from 'js-cookie';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingComponent from '../../components/LoadingComponent';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #dcdbdb`,
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#f2f2f2",
  padding: theme.spacing(2),
}));

const status = [
  'Todos os status',
  'Novo',
  'Preparando',
  'Entrega',
  'Finalizados',
  'Cancelados',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Pedidos({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {
  const { windows } = props;

  const [tipoPedido, setTipoPedido] = useState(0);
  const [tipoTela, setTipoTela] = useState("lista");
  const [pedidosOriginal, setPedidosOriginal] = useState();
  const [pedidos, setPedidos] = useState();
  const [pedidoSelecionado, setPedidoSelecionado] = useState(0);
  const [expanded, setExpanded] = useState('panel1');
  const [valorDescontos, setValorDescontos] = useState("");
  const [valorTaxas, setValorTaxas] = useState("");
  const [timestampInicio, setTimestampInicio] = useState("");
  const [timestampFim, setTimestampFim] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [isFiltrando, setIsFiltrando] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPeriodo, setAnchorElPeriodo] = useState(null);
  const [anchorElOpcoes, setAnchorElOpcoes] = useState(null);
  const [openModalRelatorio, setOpenModalRelatorio] = useState(false);
  const [periodoDe, setPeriodoDe] = useState(null);
  const [periodoAte, setPeriodoAte] = useState(null);
  const [relatorioPeriodoDe, setRelatorioPeriodoDe] = useState(null);
  const [relatorioPeriodoAte, setRelatorioPeriodoAte] = useState(null);

  const openOpcoes = Boolean(anchorElOpcoes);
  const openFiltros = Boolean(anchorEl);
  const openPeriodo = Boolean(anchorElPeriodo);
  const [statusRelatorio, setStatusRelatorio] = useState([]);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const selectFiltrosRelatorio = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('Todos os status')) {
      setStatusRelatorio(statusRelatorio.length === status.length ? [] : status);
    } else {
      setStatusRelatorio(typeof value === 'string' ? value.split(',') : value);
    }
  };
  const handleOpenOpcoes = (event) => {
    setAnchorElOpcoes(event.currentTarget);
  };

  const handleCloseOpcoes = () => {
    setAnchorElOpcoes(null);
  };

  const handleOpenFiltros = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFiltros = () => {
    setAnchorEl(null);
  };

  const handleOpenPeriodos = (event) => {
    setAnchorElPeriodo(event.currentTarget);
  };

  const handleClosePeriodos = () => {
    setAnchorElPeriodo(null);
  };

  const handleOpenRelatorios = () => {
    handleCloseOpcoes()
    setOpenModalRelatorio(true)
  };

  const handleCloseModalRelatorio = () => {
    setOpenModalRelatorio(false)
  };

  const handleChangeTipoPedido = (event, newValue) => {
    setTipoPedido(newValue); // 0 novos, 1 em andamento, 2 finalizados, 3 cancelados
  };

  const handleTipoTela = (newValue) => {
    setTipoTela(newValue);
  };

  const handleBaixarRelatorio = () => {
    handleOpenLoading();
    if (!statusRelatorio) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar pelo menos um status para baixar o relatório.`)
      return
    }
    if (!relatorioPeriodoDe || !relatorioPeriodoAte) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar as datas de inicio e fim para baixar o relatório.`)
      return
    }
    let statusEnviar = statusRelatorio.join(",")
    statusEnviar = statusEnviar.replace("Todos os status,", "").replace("Novo", "'R'").replace("Preparando", "'P'").replace("Entrega", "'E'").replace("Finalizados", "'F'").replace("Cancelados", "'C'")
    axios.post(`${urlBase}/baixar-pedidos.php?idestabelecimentos=1&status=${statusEnviar}&de=${relatorioPeriodoDe.unix()}&ate=${relatorioPeriodoAte.unix()}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `relatorio-${new Date().toLocaleDateString('pt-BR')}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        handleCloseLoading()
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  const getPedidos = (url, inicio, fim) => {
    handleOpenLoading()
    axios.post(`${url}/pedidos-filtro.php?idestabelecimentos=1&inicio=${inicio}&fim=${fim}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data
        if (response) {
          if (!pedidosOriginal) {
            setPedidosOriginal(response)
          }
          setPedidos(response)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  useEffect(() => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');
    setUrlBase(urlBase)

    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999);
    const timestampFim = Math.floor(hoje.getTime() / 1000);
    const timestampInicio = timestampFim - 172800;
    setTimestampInicio(timestampInicio)
    setTimestampFim(timestampFim)
    getPedidos(urlBase, timestampInicio, timestampFim)
  }, []);

  const getTab = (tipo) => {
    let cancelados = 0, andamento = 0, entrega = 0, novos = 0, finalizados = 0
    if (pedidos) {
      for (const pedido of pedidos) {
        if (pedido.status === "F") {
          finalizados += 1
          continue
        }
        if (pedido.status === "C") {
          cancelados += 1
          continue
        }
        if (pedido.status === "E") {
          entrega += 1
          continue
        }
        if (pedido.status === "R") {
          novos += 1
          continue
        }
        andamento += 1
      }
    }
    switch (tipo) {
      case "novos":
        return <>Novos ({novos})</>
      case "andamento":
        return <>Preparo ({andamento})</>
      case "finalizados":
        return <>Finalizados ({finalizados})</>
      case "cancelados":
        return <>Cancelados ({cancelados})</>
      case "entrega":
        return <>Entrega ({entrega})</>
    }
  }

  const getHeaderCard = (pedido) => {
    switch (pedido.status) {
      case "R":
        return <CardHeader
          avatar={
            <Circle style={{ paddingLeft: "10px", color: "#6B9F29" }} />
          }
          action={
            <Tooltip title={`Clique aqui para ver os detalhes do seu pedido`}>
              <IconButton aria-label="settings" onClick={() => handlePedido(pedido)}>
                <KeyboardArrowRightOutlined />
              </IconButton>
            </Tooltip>
          }
          title="Ver detalhes"
          titleTypographyProps={{ fontWeight: "bold" }}
          style={{ color: "#1B1B1B" }}
        />
      case "P":
        return <CardHeader
          avatar={
            <Circle style={{ paddingLeft: "10px", color: "#1976d2" }} />
          }
          action={
            <Tooltip title={`Clique aqui para ver os detalhes do seu pedido`}>
              <IconButton aria-label="settings" onClick={() => handlePedido(pedido)}>
                <KeyboardArrowRightOutlined />
              </IconButton>
            </Tooltip>
          }
          title="Preparando"
          titleTypographyProps={{ fontWeight: "bold" }}
          style={{ color: "#1B1B1B" }}
        />
      case "E":
        return <CardHeader
          avatar={
            <Circle style={{ paddingLeft: "10px", color: "#1976d2" }} />
          }
          action={
            <Tooltip title={`Clique aqui para ver os detalhes do seu pedido`}>
              <IconButton aria-label="settings" onClick={() => handlePedido(pedido)}>
                <KeyboardArrowRightOutlined />
              </IconButton>
            </Tooltip>
          }
          title={pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"}
          titleTypographyProps={{ fontWeight: "bold" }}
          style={{ color: "#1B1B1B" }}
        />
      case "F":
        return <CardHeader
          avatar={
            <CheckCircle style={{ paddingLeft: "10px", color: "#6B9F29" }} />
          }
          action={
            <Tooltip title={`Clique aqui para ver os detalhes do seu pedido`}>
              <IconButton aria-label="settings" onClick={() => handlePedido(pedido)}>
                <KeyboardArrowRightOutlined />
              </IconButton>
            </Tooltip>
          }
          title={pedido.endereco === "Retirada" ? "Retirado" : "Entregue"}
          titleTypographyProps={{ fontWeight: "bold" }}
          style={{ color: "#1B1B1B" }}
        />
      case "C":
        return <CardHeader
          avatar={
            <Circle style={{ paddingLeft: "10px", color: "#F94A3D" }} />
          }
          action={
            <Tooltip title={`Clique aqui para ver os detalhes do seu pedido`}>
              <IconButton aria-label="settings" onClick={() => handlePedido(pedido)}>
                <KeyboardArrowRightOutlined />
              </IconButton>
            </Tooltip>
          }
          title="Pedido cancelado"
          titleTypographyProps={{ fontWeight: "bold" }}
          style={{ color: "#1B1B1B" }}
        />
    }
  }

  const validaTipoPedido = (status) => {
    if (tipoPedido === 0 && status === "R") {
      return true
    }
    if (tipoPedido === 1 && status === "P") {
      return true
    }
    if (tipoPedido === 2 && status === "E") {
      return true
    }
    if (tipoPedido === 3 && status === "F") {
      return true
    }
    if (tipoPedido === 4 && status === "C") {
      return true
    }
    return false
  }

  const validaZeroPedidos = (pedidos) => {
    let count = 0
    for (const pedido of pedidos) {
      const status = pedido.status
      if (tipoPedido === 0 && status === "R") {
        count += 1
      }
      if (tipoPedido === 1 && status === "P") {
        count += 1
      }
      if (tipoPedido === 2 && status === "E") {
        count += 1
      }
      if (tipoPedido === 3 && status === "F") {
        count += 1
      }
      if (tipoPedido === 4 && status === "C") {
        count += 1
      }
    }

    if (count === 0) {
      return true
    }
    return false
  }

  const handlePedido = (pedido) => {
    calculaValorTotalPedido(pedido)
    setPedidoSelecionado(pedido)
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getSubtotal = () => {
    return toReal(parseFloat(pedidoSelecionado.valor.replaceAll(".", "").replaceAll(",", ".")) - (pedidoSelecionado.taxa && pedidoSelecionado.taxa != "combinar" ? parseFloat(pedidoSelecionado.taxa.replaceAll(".", "").replaceAll(",", ".")) : 0))
  };

  const getTaxaEntrega = () => {
    if (pedidoSelecionado.taxa === "combinar") {
      return "A Combinar"
    }
    return `R$ ${toReal((pedidoSelecionado.taxa ? parseFloat(pedidoSelecionado.taxa.replaceAll(".", "").replaceAll(",", ".")) : "0,00"))}`

  };

  const getPedidoStatus = (pedido) => {
    
    let statusPedido = "", horaPedido = ""
    if (pedido.status === "F") {
      pedido.endereco === "Retirada" ? statusPedido = "Retirado" : statusPedido = "Entregue"
    } else {
      statusPedido = "Pedido realizado"
    }

    if (!pedido.demaishoras) {
      if (pedido.status === "C") {
        return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><Cancel style={{ color: "#F94A3D", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; Pedido cancelado</Typography>
            <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{toHour(pedido.data)}</Typography>
          </AccordionSummary>
        </Accordion>;
      }
      if (pedido.status === "P") {
        return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><Circle style={{ color: "#1976d2", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; Preparando</Typography>
            <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{toHour(pedido.data)}</Typography>
          </AccordionSummary>
        </Accordion>;
      }
      if (pedido.status === "E") {
        pedido.endereco === "Retirada" ? statusPedido = "Aguardando retirada" : statusPedido = "Saiu para entrega"
        return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><Circle style={{ color: "#1976d2", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}</Typography>
            <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{toHour(pedido.data)}</Typography>
          </AccordionSummary>
        </Accordion>;
      }
      return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}><CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}</Typography>
          <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{toHour(pedido.data)}</Typography>
        </AccordionSummary>
      </Accordion>;
    }

    const arrHoras = pedido.demaishoras.split(",");
    if (arrHoras[2]) {
      statusPedido = statusPedido
      horaPedido = toHour(arrHoras[2])
    }

    if (arrHoras[1] && !arrHoras[2]) {
      statusPedido = pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"
      horaPedido = toHour(arrHoras[1])
    }

    if (arrHoras[0] && !arrHoras[1] && !arrHoras[2]) {
      statusPedido = "Preparando"
      horaPedido = toHour(arrHoras[0])
    }

    if (pedido.status === "C") {
      statusPedido = "Pedido cancelado"
    }

    if (pedido.status === "E" || statusPedido === "Pedido realizado") {
      statusPedido = pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"
    }
    if (pedido.status === "P" || statusPedido === "Pedido realizado") {
      statusPedido = "Preparando"
    }

    return <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ borderRadius: "8px" }}>

      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#1B1B1B" }}>
          {pedido.status === "C" &&
            <>
              <Cancel style={{ color: "#F94A3D", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}
            </>
          }
          {(pedido.status === "R" || pedido.status === "F") &&
            <>
              <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}
            </>
          }
          {pedido.status !== "R" && pedido.status !== "F" && pedido.status !== "C" &&
            <>
              <Circle style={{ color: "#1976d2", fontSize: "16px", marginBottom: "-2px", marginLeft: "-8px" }} /> &nbsp;&nbsp; {statusPedido}
            </>
          }
        </Typography>
        <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "5px", paddingRight: "10px" }}>{horaPedido}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>

        {arrHoras[1] && arrHoras[2] && (
          <>
            <Typography style={{ display: "flex", alignContent: "center" }}>
              <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; {pedido.endereco === "Retirada" ? "Aguardando retirada" : "Saiu para entrega"}
              </Typography>
              <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
                {toHour(arrHoras[1])}
              </Typography>
            </Typography>

            <div style={{ height: "35px" }}></div>
          </>
        )}

        {arrHoras[0] && arrHoras[1] && (
          <>
            <Typography style={{ display: "flex", alignContent: "center" }}>
              <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; Preparando
              </Typography>
              <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
                {toHour(arrHoras[0])}
              </Typography>
            </Typography>

            <div style={{ height: "35px" }}></div>
          </>
        )}

        <Typography style={{ display: "flex", alignContent: "center" }}>
          <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
            {pedido.status === "C" ?
              <>
                <Cancel style={{ color: "#F94A3D", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; Pedido cancelado
              </>
              :
              <>
                <CheckCircle style={{ color: "#33540E", fontSize: "16px", marginBottom: "-2px" }} /> &nbsp;&nbsp; Pedido realizado
              </>
            }
          </Typography>
          <Typography style={{ fontSize: "10px", marginLeft: "auto", color: "#999999", paddingTop: "4px" }}>
            {toHour(pedido.data)}
          </Typography>
        </Typography>
      </AccordionDetails>
    </Accordion>
  };

  const calculaValorTotalPedido = (pedido) => {
    let valorTotal = pedido.valor
    let tservico, tdesconto, vservico, vdesconto
    if (pedido.servico) {
      const arr = pedido.servico.split("--")
      tservico = arr[0]
      vservico = arr[1]
    } else {
      tservico = "p"
      vservico = "0"
    }
    if (pedido.desconto) {
      const arr = pedido.desconto.split("--")
      tdesconto = arr[0]
      vdesconto = arr[1]
    } else {
      tdesconto = "p"
      vdesconto = "0"
    }

    const opcaoTaxa = tservico
    const opcaoDesconto = tdesconto

    let valorTaxa = parseFloat(vservico.replaceAll(".", "").replaceAll(",", "."))
    let valorDesconto = parseFloat(vdesconto.replaceAll(".", "").replaceAll(",", "."))
    isNaN(valorTaxa) ? valorTaxa = 0 : console.log("-- DEBUG --")
    isNaN(valorDesconto) ? valorDesconto = 0 : console.log("-- DEBUG --")
    if (opcaoTaxa == "r") {
      setValorTaxas(valorTaxa !== 0 ? toReal(valorTaxa) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) + valorTaxa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorTaxas(valorTaxa !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorTaxa / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 + (valorTaxa / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    if (opcaoDesconto == "r") {
      setValorDescontos(valorDesconto !== 0 ? toReal(valorDesconto) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) - valorDesconto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorDescontos(valorDesconto !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorDesconto / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 - (valorDesconto / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return valorTotal
  }

  const handleAtualizarPedido = (event, produto, status) => {
    event.stopPropagation();
    let arrHora
    const timestampAtual = Math.floor(new Date().getTime() / 1000);
    let demaishoras = produto.demaishoras
    demaishoras ? arrHora = demaishoras.split(",") : arrHora = (",,").split(",");
    switch (status) {
      case "P":
        arrHora[0] = timestampAtual;
        break;
      case "E":
        arrHora[1] = timestampAtual;
        break;
      case "F":
        arrHora[2] = timestampAtual;
        break;
    }
    demaishoras = (`${arrHora[0]},${arrHora[1]},${arrHora[2]}`).replaceAll("undefined", "")
    const post_url = `${urlBase}/editar-pedido.php`;
    const json = JSON.stringify({
      idpedidos: produto.idpedidos,
      status: status,
      demaishoras: demaishoras
    });

    axios.post(post_url, json)
      .then(resp => {
        console.log(resp.data)
        getPedidos(urlBase, timestampInicio, timestampFim);
      })
  }

  const getNomeCliente = (pedido) => {
    if (pedido.nomecliente && pedido.nomecliente.includes("--")) {
      const arr = pedido.nomecliente.split("--");
      return <>{arr[2].toUpperCase()}</>
    }
    return pedido.nomecliente
  }

  const getTipoPedido = (pedido) => {
    if (pedido.nomecliente && pedido.nomecliente.includes("--")) {
      const arr = pedido.nomecliente.split("--");
      return <>Mesa {arr[0]}</>
    }
    if (pedido.endereco == "Retirada") {
      return <>Retirada</>
    }
    return <>Entrega</>
  }

  const getButtons = (pedido, status) => {
    switch (status) {
      case "R":
        return <>
          <Button variant="text" color="error" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "C")}>Cancelar</Button>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "P")}>Confirmar</Button>
        </>
      case "P":
        return <>
          <IconButton color="error" style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}>
            <UndoOutlined />
          </IconButton>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "E")}>Avançar</Button>
        </>
      case "E":
        return <>
          <IconButton color="error" style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "P")}>
            <UndoOutlined />
          </IconButton>
          <Button variant="text" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "F")}>Confirmar</Button>
        </>
      case "F":
        return <>
          <Button variant="text" color="success" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}> <SettingsBackupRestoreOutlined />&nbsp;&nbsp;Reativar</Button>
        </>
      case "C":
        return <>
          <Button variant="text" color="success" fullWidth style={{ fontWeight: "bold", textTransform: "none" }} onClick={(event) => handleAtualizarPedido(event, pedido, "R")}> <SettingsBackupRestoreOutlined />&nbsp;&nbsp;Restaurar</Button>
        </>
    }

  }

  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };

  const handleCancelarFiltros = () => {
    handleCloseFiltros()
    handleClosePeriodos()
    setPedidos(pedidosOriginal);
    setIsFiltrando(false)
  };

  const handleFiltrar = () => {
    if (!periodoDe) {
      update("", `${Date.now()}___warning___Informe a data de inicio do período para buscar`)
      return
    }
    if (!periodoAte) {
      update("", `${Date.now()}___warning___Informe a data final do período para buscar`)
      return
    }
    setTimestampInicio(periodoDe.unix())
    setTimestampFim(periodoAte.unix() + 86399)
    getPedidos(urlBase, periodoDe.unix(), periodoAte.unix() + 86399)
    // setPedidos(pedidosOriginal.filter(item => parseInt(item.data) > periodoDe.unix() && parseInt(item.data) <= (periodoAte.unix() + 86399)));
    handleCloseFiltros()
    handleClosePeriodos()
    setIsFiltrando(true)
  };

  const getDataFiltro = (filtro) => {
    switch (filtro) {
      case "de":
        return periodoDe.format('DD/MM/YYYY')
      case "ate":
        return periodoAte.format('DD/MM/YYYY')
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <Dialog
        open={openModalRelatorio}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 640 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <AttachFileOutlined />&nbsp;&nbsp;Relatório
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalRelatorio}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>

          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            Status
          </Typography>
          <FormControl fullWidth>
            <Select
              multiple
              value={statusRelatorio}
              onChange={selectFiltrosRelatorio}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {status.map((stats) => (
                <MenuItem key={stats} value={stats}>
                  <Checkbox checked={statusRelatorio.indexOf(stats) > -1} />
                  <ListItemText primary={stats} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 16 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>De</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={relatorioPeriodoDe}
                  onChange={(newValue) => setRelatorioPeriodoDe(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Até</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={relatorioPeriodoAte}
                  onChange={(newValue) => setRelatorioPeriodoAte(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
          </div>



        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseModalRelatorio} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          <Button variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={handleBaixarRelatorio}>Baixar relatório</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute", overflow: "hidden" }}>
        <Grid container spacing={2} style={{ padding: "16px 0px 16px 16px" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={7} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", height: "100vh" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Pedidos</Typography>
                  <div style={{ marginLeft: "auto" }}>
                    <Tooltip title={`Filtrar seus pedidos`}>
                      <IconButton onClick={handleOpenFiltros} aria-controls={openFiltros ? 'filtros' : undefined} aria-haspopup="true" aria-expanded={openFiltros ? 'true' : undefined} size="medium" color="inherit" style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48 }}>
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                    <Menu id="filtros" anchorEl={anchorEl} open={openFiltros} onClose={handleCloseFiltros}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Typography style={{ color: "#999", fontSize: 14, paddingLeft: 16 }}>Filtros</Typography>
                      <MenuItem onClick={handleOpenPeriodos}>Período &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ChevronRight /></MenuItem>
                      <Menu id="periodo" anchorEl={anchorElPeriodo} open={openPeriodo} onClose={handleClosePeriodos}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                          vertical: isMobile ? "bottom" : "top",
                          horizontal: isMobile ? "center" : "right",
                        }}

                      >
                        <div style={{ padding: 16 }}>
                          <Typography style={{ fontSize: 14, fontWeight: "bold", padding: "0px 10px 0px 0px" }}>De</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DatePicker
                              value={periodoDe}
                              onChange={(newValue) => setPeriodoDe(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                          <Typography style={{ fontSize: 14, fontWeight: "bold", padding: "10px 10px 0px 0px" }}>Até</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DatePicker
                              value={periodoAte}
                              onChange={(newValue) => setPeriodoAte(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                          <div style={{ height: 16 }} />
                          <div style={{ display: "flex", justifyContent: "right" }}>
                            <Button variant="outlined" color="error" style={{ fontWeight: "bold", textTransform: "none" }} onClick={() => handleCancelarFiltros()}>Cancelar</Button>
                            <div style={{ width: 16 }} />
                            <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} onClick={() => handleFiltrar()}>Filtrar</Button>
                          </div>
                        </div>
                      </Menu>
                    </Menu>
                    <Tooltip title={`Visualização dos itens por lista`}>
                      <IconButton size="medium" color="inherit" onClick={() => handleTipoTela("lista")} style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48, border: tipoTela === "lista" ? `solid 2px rgba(${colorPrimary})` : "" }}>
                        <ViewStreamOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Visualização dos itens por grade`}>
                      <IconButton size="medium" color="inherit" onClick={() => handleTipoTela("grade")} style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48, border: tipoTela === "grade" ? `solid 2px rgba(${colorPrimary})` : "" }}>
                        <GridOnOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Mais opções`}>
                      <IconButton onClick={handleOpenOpcoes} aria-controls={openOpcoes ? 'opcoes' : undefined} aria-haspopup="true" aria-expanded={openOpcoes ? 'true' : undefined} size="medium" color="inherit" style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48 }}>
                        <MoreVertOutlined />
                      </IconButton>
                    </Tooltip>
                    <Menu id="opcoes" anchorEl={anchorElOpcoes} open={openOpcoes} onClose={handleCloseOpcoes}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Typography style={{ color: "#999", fontSize: 14, paddingLeft: 16 }}>Mais ações</Typography>
                      <MenuItem onClick={handleOpenRelatorios}><AttachFileOutlined />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Relatório </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div>
                  <Tabs value={tipoPedido} onChange={handleChangeTipoPedido} variant="scrollable" scrollButtons="auto" style={{ paddingRight: isMobile ? 0 : 16 }} >
                    <Tab label={getTab("novos")} {...a11yProps(0)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
                    <Tab label={getTab("andamento")} {...a11yProps(1)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
                    <Tab label={getTab("entrega")} {...a11yProps(2)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
                    <Tab label={getTab("finalizados")} {...a11yProps(3)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
                    <Tab label={getTab("cancelados")} {...a11yProps(4)} style={{ textTransform: "none", borderBottom: "1px solid #CCC" }} />
                  </Tabs>
                  <div style={{ height: 20 }} />
                  <Grid container spacing={2} style={{ maxHeight: "calc(100vh - 192px)", overflow: "auto", paddingBottom: 24, paddingRight: 16 }}>
                    {isFiltrando &&
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography style={{ display: isMobile ? "" : "flex", justifyContent: "left", alignItems: "center", fontWeight: "500", fontSize: 14, color: "#464545", background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                          Limpar filtros
                          <div style={{ width: 20, height: 10 }} />
                          <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>De {getDataFiltro("de")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                          <div style={{ width: 8, height: 8 }} />
                          <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>Até {getDataFiltro("ate")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />

                        </Typography>
                      </Grid>
                    }
                    {pedidos &&
                      <>
                        {validaZeroPedidos(pedidos) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 328px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                                <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                                <div style={{ height: "10px" }}></div>

                                Não há pedidos para listar

                                <div style={{ height: "20px" }}></div>
                              </div>
                            </Grid>
                          </>
                        )}
                        {pedidos.map((pedido) => (
                          <>
                            {validaTipoPedido(pedido.status) &&
                              <Grid item xs={12} sm={12} md={tipoTela === "grade" ? 4 : 6}>

                                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                                  {getHeaderCard(pedido)}

                                  <Divider />
                                  <CardContent>
                                    <Typography style={{ display: "flex", fontSize: "12px", color: "#999", alignItems: "center" }}>
                                      <b style={{ fontWeight: "normal" }}><b style={{ color: "#464545" }}>{getTipoPedido(pedido)}</b><br />N° {pedido.data}</b>
                                      <b style={{ marginLeft: "auto" }}>{getDatahora(pedido.datahora)}</b>
                                    </Typography>
                                    <div style={{ height: "10px" }}></div>
                                    <Typography style={{ fontSize: "14px", fontWeight: 600, color: "#464545" }}>
                                      {getNomeCliente(pedido)}
                                    </Typography>

                                  </CardContent>

                                  <CardActions disableSpacing>
                                    <Typography style={{ textAlign: "center", width: "100%", display: "flex" }}>
                                      {getButtons(pedido, pedido.status)}
                                    </Typography>
                                  </CardActions>

                                  <div style={{ height: "16px" }}></div>
                                </Card>

                              </Grid>
                            }
                          </>
                        ))}
                      </>
                    }

                  </Grid>
                </div>
              </Grid>

              {pedidoSelecionado === 0 ?
                <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                    <img src="https://dedicado.mely.online/imagens/no-order-est.png" style={{ width: 155 }} />

                    <div style={{ height: "10px" }}></div>

                    Selecione um pedido ao lado para ver mais detalhes

                    <div style={{ height: "20px" }}></div>
                  </div>
                </Grid>
                :
                <>
                  <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                    <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>Pedido {pedidoSelecionado.data}
                      <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setPedidoSelecionado(0)}>
                        <Close />
                      </IconButton>
                    </Typography>
                    <div style={{ height: 10 }} />
                    <Divider style={{ width: "120%", marginLeft: -16 }} />
                    <div style={{ height: 24 }} />

                    {getPedidoStatus(pedidoSelecionado)}

                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Detalhes do pedido</Typography>
                    <div style={{ height: 10 }} />
                    {(JSON.parse(atob(pedidoSelecionado.detalhes))).map((detalhes) => (
                      <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 16, marginTop: 5 }}>

                        <>
                          <label>{detalhes.quantidade}x {detalhes.nome}<br />
                            <b style={{ color: "#999", fontWeight: "normal", fontSize: 14 }}>{detalhes.observacoes ? detalhes.observacoes : "Sem observações"}</b>
                          </label>

                          <b style={{ marginLeft: "auto" }}>R$ {detalhes.valorTotal}</b>
                        </>
                      </Typography>
                    ))}

                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Subtotal
                      <b style={{ marginLeft: "auto" }}>R$ {getSubtotal()}</b>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Taxa de entrega
                      <b style={{ marginLeft: "auto" }}>{getTaxaEntrega()}</b>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Taxa de serviço
                      <b style={{ marginLeft: "auto" }}>R$ {valorTaxas}</b>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Desconto
                      <b style={{ marginLeft: "auto" }}>R$ {valorDescontos}</b>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 18 }}>
                      Total
                      <b style={{ marginLeft: "auto" }}>R$ {pedidoSelecionado.valor}</b>
                    </Typography>
                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Forma de pagamento</Typography>
                    <div style={{ height: 10 }} />
                    <Typography style={{ fontWeight: "400", fontSize: 18, textTransform: "none" }}>
                      {pedidoSelecionado.formapagamento}
                    </Typography>

                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados da entrega</Typography>
                    <div style={{ height: 10 }} />
                    <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                      <CardHeader
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        title={pedidoSelecionado.nomecliente}
                        titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                        subheader={pedidoSelecionado.endereco}
                        subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                      />
                    </Card>
                    <div style={{ height: 24 }} />

                  </Grid>

                  {isMobile &&
                    <SwipeableDrawer
                      container={container}
                      anchor="bottom"
                      open={pedidoSelecionado !== 0}
                      onClose={() => setPedidoSelecionado(0)}
                      disableSwipeToOpen={true}
                      disableDiscovery={true}
                      ModalProps={{
                        keepMounted: true,
                        style: { zIndex: "10000" }
                      }}
                    >
                      <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} style={{ padding: 16, overflowX: "hidden", overflowY: "auto" }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>Pedido {pedidoSelecionado.data}
                          <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setPedidoSelecionado(0)}>
                            <Close />
                          </IconButton>
                        </Typography>
                        <div style={{ height: 10 }} />
                        <Divider style={{ width: "120%", marginLeft: -16 }} />
                        <div style={{ height: 24 }} />

                        {getPedidoStatus(pedidoSelecionado)}

                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Detalhes do pedido</Typography>
                        <div style={{ height: 10 }} />
                        {(JSON.parse(atob(pedidoSelecionado.detalhes))).map((detalhes) => (
                          <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 16, marginTop: 5 }}>

                            <>
                              <label>{detalhes.quantidade}x {detalhes.nome}<br />
                                <b style={{ color: "#999", fontWeight: "normal", fontSize: 14 }}>{detalhes.observacoes ? detalhes.observacoes : "Sem observações"}</b>
                              </label>

                              <b style={{ marginLeft: "auto" }}>R$ {detalhes.valorTotal}</b>
                            </>
                          </Typography>
                        ))}

                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Subtotal
                          <b style={{ marginLeft: "auto" }}>R$ {getSubtotal()}</b>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Taxa de entrega
                          <b style={{ marginLeft: "auto" }}>{getTaxaEntrega()}</b>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Taxa de serviço
                          <b style={{ marginLeft: "auto" }}>R$ {valorTaxas}</b>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Desconto
                          <b style={{ marginLeft: "auto" }}>R$ {valorDescontos}</b>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 18 }}>
                          Total
                          <b style={{ marginLeft: "auto" }}>R$ {pedidoSelecionado.valor}</b>
                        </Typography>
                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Forma de pagamento</Typography>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "400", fontSize: 18, textTransform: "none" }}>
                          {pedidoSelecionado.formapagamento}
                        </Typography>

                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados da entrega</Typography>
                        <div style={{ height: 10 }} />
                        <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                          <CardHeader
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            title={pedidoSelecionado.nomecliente}
                            titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                            subheader={pedidoSelecionado.endereco}
                            subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                          />
                        </Card>
                        <div style={{ height: 24 }} />
                      </div>
                    </SwipeableDrawer>
                  }
                </>
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Pedidos;