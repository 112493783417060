import { Container, Typography, IconButton, Button } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as React from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Finalizado() {

  const navigate = useNavigate();
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [urlRedirect, setUrl] = useState("");
  const [isMesa, setIsMesa] = useState("");
  const [pedidos, setPedidos] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const getChavePix = () => {
    return Cookies.get('cvP');
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if(numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: "home" } })
    } else {
      navigate("/", { replace: true, state: { page: "home" } })
    }
  };

  const handlePedidos = () => {
    const numeroMesa = Cookies.get('nM');
    if(numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: "pedidos" } })
    } else {
      navigate("/", { replace: true, state: { page: "pedidos" } })
    }
  };

  const handleCopy = async () => {
    try {
      const chavePix = Cookies.get('cvP');
      await navigator.clipboard.writeText(chavePix);
      handleSnack("Chave PIX copiada com sucesso.")
    } catch (err) {
      handleSnack("Não foi possivel copiar a chave PIX")
    }
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;
    
    const urlMessage = Cookies.get('UMs');
    setUrl(urlMessage);
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
    }
    getPedidos(state.pedido);
  }, []);

  const getPedidos = (idp) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido.php?idpedidos=${idp}`)
      .then(response => {
        if (response.data) {
          setPedidos(response.data);
          handleCloseLoading();
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  return (
    <Container>

      <LoadingComponent open={loading} />

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
      />

      <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#1B1B1B" }} onClick={handleBack}>
        <CloseOutlined />
      </IconButton>

      <div style={{ height: "70px" }}></div>
      {pedidos && (
        <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
          <Typography style={{ textAlign: "center", fontSize: "14px", color: "#999", minWidth: "300px" }}>

            <div style={{ height: "10px" }}></div>

            <img src="https://dedicado.mely.online/imagens/smiling-face.png" style={{ width: "90px" }} />

            <div style={{ height: "10px" }}></div>

            <Typography style={{ textAlign: "center", fontSize: "24px", color: "#1B1B1B", minWidth: "300px" }}>
              Pedido #{pedidos[0].data} criado!
            </Typography>
            {isMesa ? (
              <>
                Seu pedido foi realizado com sucesso, em breve estará em sua mesa!
              </>
            ) : (
              <>
                Seu pedido foi realizado com sucesso, uma cópia foi enviada ao seu whatsapp!
              </>
            )}
            {pedidos[0].formapagamento === "PIX" && (
              <>
                <br /><br />
                Chave PIX: <b>{getChavePix()}</b>
              </>
            )}
            <div style={{ height: "20px" }}></div>
            {pedidos[0].formapagamento === "PIX" && (
              <>
                <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleCopy} target="_blank">Copiar chave PIX</Button>
                <br /><br />
              </>
            )}
            {pedidos[0].formapagamento === "PIX" && (
              <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} href={urlRedirect} target="_blank">Enviar comprovante de pagamento</Button>
            )}
            {pedidos[0].formapagamento !== "PIX" && !isMesa && (
              <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} href={urlRedirect} target="_blank">Falar com estabelecimento</Button>
            )}
          </Typography>
        </Typography>
      )}

      <div style={{ position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
        <Button fullWidth variant="contained" size="large" style={{ textTransform: "none", fontWeight: "700" }} onClick={handlePedidos}>
          Pedidos
        </Button>
      </div>

      <div style={{ height: "100px" }}></div>

    </Container>
  );
}

export default Finalizado;